import React from "react";

function AdroxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      fill="none"
      viewBox="0 0 107 107"
    >
      <g fill="#fff" clipPath="url(#clip0_1421_1519)">
        <path d="M71.642 18.123L53.506.027 35.369 18.123l18.137 18.095 18.136-18.095zM53.506 6.88l11.26 11.242-11.268 11.241-11.267-11.241L53.506 6.88zM70.709 54.025L88.845 72.12l18.136-18.095L88.845 35.93 70.709 54.025zm18.136 11.242L77.578 54.025l11.267-11.238 11.271 11.238-11.271 11.242zM36.288 54.025L18.15 35.93.015 54.025 18.151 72.12l18.137-18.095zM18.15 42.787L29.42 54.025 18.125 65.267 6.858 54.025 18.15 42.787zM35.354 88.892l18.136 18.096 18.137-18.096L53.49 70.797 35.353 88.892zm18.136 11.242L42.23 88.892 53.499 77.65l11.267 11.242-11.275 11.242z"></path>
        <path d="M86.23 73.676l-19.935-19.89L86.226 33.9a3.029 3.029 0 003.204-1.58 3.01 3.01 0 00-.547-3.525 3.025 3.025 0 00-4.847.801 3.01 3.01 0 00-.266 1.857L63.843 51.34l-3.023-3.016 19.935-19.886a3.029 3.029 0 003.204-1.581 3.01 3.01 0 00-.547-3.524 3.025 3.025 0 00-5.113 2.658L58.365 45.877l-2.668-2.665 19.935-19.886a3.028 3.028 0 003.203-1.581 3.01 3.01 0 00-.547-3.524 3.025 3.025 0 00-4.847.8 3.01 3.01 0 00-.265 1.858L53.24 40.77l-19.93-19.894a3.01 3.01 0 00-1.582-3.198 3.029 3.029 0 00-3.533.542 3.014 3.014 0 00.8 4.838 3.03 3.03 0 001.862.265l19.935 19.886-2.672 2.665-19.934-19.89a3.01 3.01 0 00-1.582-3.198 3.029 3.029 0 00-3.532.542 3.014 3.014 0 00.8 4.838 3.029 3.029 0 001.861.265L45.67 48.316l-3.022 3.016-19.931-19.89a3.01 3.01 0 00-1.58-3.198 3.029 3.029 0 00-3.533.54 3.014 3.014 0 002.657 5.105l19.93 19.897-19.934 19.89a3.028 3.028 0 00-3.204 1.58 3.01 3.01 0 00.545 3.523 3.025 3.025 0 004.847-.796 3.01 3.01 0 00.268-1.857l19.93-19.89 2.672 2.662-19.935 19.89a3.03 3.03 0 00-3.203 1.58 3.01 3.01 0 00.545 3.523 3.025 3.025 0 004.847-.796 3.01 3.01 0 00.267-1.857L47.77 61.352l3.023 3.016-19.935 19.89a3.03 3.03 0 00-3.206 1.578 3.01 3.01 0 00.544 3.525 3.026 3.026 0 004.848-.799 3.01 3.01 0 00.266-1.857l19.931-19.886 19.935 19.878a3.01 3.01 0 001.58 3.199 3.029 3.029 0 003.532-.54 3.013 3.013 0 00-.795-4.838 3.029 3.029 0 00-1.861-.267L55.697 64.36l3.023-3.016 19.934 19.89a3.01 3.01 0 001.584 3.196 3.029 3.029 0 003.531-.544 3.013 3.013 0 00-.798-4.836 3.029 3.029 0 00-1.86-.267l-19.935-19.89 2.667-2.657 19.935 19.886a3.01 3.01 0 001.583 3.196 3.03 3.03 0 003.532-.544 3.014 3.014 0 00-2.66-5.103l-.003.004zM58.716 56.452l-2.452 2.446-3.023 3.016-3.023-3.016-2.456-2.447-2.667-2.665 3.023-3.016 2.456-2.45 2.667-2.662 2.671 2.662 2.453 2.45 3.022 3.016-2.671 2.666z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1421_1519">
          <path fill="#fff" d="M0 0H107V107H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AdroxIcon;